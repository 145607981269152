<template>
  <div>
    <Header></Header>
    <div class="content">
       <!-- 轮播 -->
       <swiper :options="swiperOption" ref="mySwiper">
          <swiper-slide v-for="(item, index) in bannerList" :key="index">
            <img :src="oss + item" class="cir_bg" alt="" />
          </swiper-slide>
        </swiper>
      <!-- <img src="../assets/static/images/img_mycir1.png" class="cir_bg" alt="" /> -->
      <div class="bread_nav flex-box">
        <div class="bread fs16 col3 plr30">
          当前位置：<router-link to="/">首页</router-link>><a href="javascript:;">证书案例</a>
        </div>
      </div>
      <div class="my_cir m1440">
        <div class="fs32 tc pt60">证书案例</div>
        <ul class="cir_list flex-box flex-col-start flex-wrap">
          <li v-for="(item, index) in list" :key="index" v-viewer="{movable: false}">
            <img :src="oss + item" class="certificate" :key="item" alt=""
            />
          </li>
        </ul>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
  import 'viewerjs/dist/viewer.css'
  import { directive as viewer } from "v-viewer"
  import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  directives: {
      viewer: viewer({
          debug: true,
      }),
    },
    components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      },
      oss:this.global.uploadOss,
      list: [],
      bannerList: [],
    }
  },
   // 页面渲染完执行
   created() {
    let _that = this;
    _that.get_list();
  },
  methods: {
  get_list() {
      this.$api.apiIndexBanner({ contentKeys: 'PC_BANNER,PC_CERTIFICATE' }).then((data_res) => {
        //console.log(data_res)
        if (data_res.resCode == 0) {
          let data = data_res.root
          data.forEach(item=>{
              if(item.ckName=='PC_BANNER'){
                var img = item.pictureUrl.split(',')
                this.bannerList = img
              }else{
                var img = item.pictureUrl.split(',')
                this.list = img
              }
          })
        } else {
          this.$message.error(data_res.resMsg);
        }
      });
    },

  },
};
</script>

<style scoped>
.swiper-container {
  height: 40vh;
}

.img_banner {
  object-fit: cover;
  -o-object-fit: cover;
}
</style>
